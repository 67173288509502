<template>
	<div class="page-manage">
		<div style="width: 500px;background-color: #fff;padding: 100px 10px;">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
			<el-form-item label="活动名称" prop="activityName">
				<el-input v-model="ruleForm.activityName" placeholder="最多输入20个字" maxlength="20" style="width:360px"></el-input>
			</el-form-item>
			<el-form-item label="活动时间" required prop="applyTime">
				<el-date-picker style="width: 360px;" v-model="ruleForm.applyTime" type="datetimerange"
				range-separator="～" start-placeholder="开始日期" end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
			</el-form-item>
			</el-form>
		</div>
		<browse-box ref="browseBox" :list="list" :isSetData="isAddData" @submit="submit" @moveUp="moveUp" @moveDown="moveDown"
		@remove="remove">
		</browse-box>

		<div class="edit-box">
			<edit-box :list="list" :isTheme="true" @move="handleCanMove"></edit-box>
		</div>

		<div class="btn-box">
			<el-button plain size="mini" @click="cancel">取消</el-button>
			<!-- <el-button plain size="mini" @click="preview">预览</el-button> -->
			<el-button v-if="type!=2" plain size="mini" @click="submit(0)">保存页面</el-button>
			<!-- <el-button type="primary" size="mini" @click="submit(1)">保存并发布</el-button> -->
		</div>

		<div class="fixed-menu" @click="showMenu=!showMenu">
			<!-- <Icon type="android-apps" :size="20" color="#1890ff"></Icon> -->
			<i class="el-icon-tickets" style="font-size: 20px;color:#1890ff;"></i>
		</div>

		<transition name="scaleRight">
			<div class="dragMenu" v-if="showMenu">
				<div class="box">
					<p class="title">模块管理</p>
					<draggable class="list" :list="list" :options="{animation: 100, handle:'.item'}">
						<div class="item" @click="scrollToElement(item)" v-for="(item, index) in list" :key="index" v-if="item.moduleType !== 1">
							{{item.tipName}}

							<span class="menu-delete" @click.stop="deleteMenu(index)" title="删除模块">
								<Icon type="trash-a" :size="20" color="#1890ff"></Icon>
							</span>
						</div>
					</draggable>
				</div>
			</div>
		</transition>


		<el-dialog title="提示" :visible.sync="dialogVisible" width="400px">
			<span style="line-height: 24px;">若您未保存编辑的数据，离开页面后，系统将不会为您保存数据，是否确认继续离开？</span>
			<span slot="footer" class="dialog-footer">
				<el-button size="mini" @click="dialogVisible = false">关闭</el-button>
				<el-button size="mini" type="primary" @click="sureCancel">确定离开</el-button>
			</span>
		</el-dialog>

		<el-dialog title="预览" :visible.sync="weiCodeShow" width="500px" @closed="weiCodeCancel">
			<div v-loading="codeding">
				<img :src="imgUrl+codePicture" alt="" v-if="codePicture" id="codeId" style="width:300px;display: block;margin: 30px auto">
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import menuList from './components/menuList';
	import browseBox from './components/browseBox';
	import editBox from './components/editBox';
	import batchCods from "@/components/bathCodes"

	import draggable from 'vuedraggable';
	import config from '@/config/index'
	import {
		subjectActivitysaveActivity,
		subjectActivityupdateActivity,
		subjectActivityfindByActId
	} from "@/api/goods";
	import apiList from '@/api/other';

	export default {
		name: 'page-manage',
		components: {
			menuList,
			browseBox,
			editBox,
			draggable,
			batchCods
		},
		data() {
			return {
				list: [],
				imgUrl: config.IMG_BASE,
				pageId: this.$route.query.id || 0,
				isAddData: false,
				showMenu: false,
				dialogVisible: false,
				indexList: [],
				weiCodeData: {},
				weiCodeShow: false,
				codeding: false,
				codePicture: '',
				isGroup: this.$route.query.isGroup === 'group',
				type:0,//0添加 1编辑 2查看
				ruleForm:{
					activityName:'',
					applyTime:'',
				},
				rules: {
					activityName: [{
						required: true,
						message: '请填写活动名称',
						trigger: 'change'
					}],
					applyTime: [{
						required: true,
						message: '请选择活动时间',
						trigger: 'change'
					}],
				},
			};
		},
		methods: {
			async initData() {
				this.type = this.$route.query.type;
				if (!this.pageId || this.pageId == '0') {
					this.setNormal();
					return;
				}
				let res = await subjectActivityfindByActId({
					id:this.pageId
				})
				
				try {
					this.ruleForm.activityName = res.data.activityName;
					if(res.data.startTime&&res.data.endTime){
						this.ruleForm.applyTime = [res.data.startTime,res.data.endTime];
					}
					var list = JSON.parse(res.data.activityContext);
					list.map(item => {
						item.uid = item.uid || this.guid();
				
						if (!item.tipName && item.moduleType != 1) {
							item = this.filterRecord(item);
							if (this.indexList[item.moduleType]) {
								this.indexList[item.moduleType].push(item.index);
							} else {
								item.tipName = item.name + 1;
								item.index = item.index;
								this.indexList[item.moduleType] = [];
								this.indexList[item.moduleType].push(1);
							}
						} else {
							this.indexList[item.moduleType] = this.indexList[item.moduleType] || [];
							this.indexList[item.moduleType].push(item.index);
						}
						return item;
					})
				
					this.list = list;
				
					console.log(this.list, 'this.list')
					if (!this.list.length) {
						this.setNormal();
					}
				} catch (error) {
					console.log(error)
					this.setNormal();
				}
			},
			setNormal() {
				this.list.push({
					moduleType: 12,
					name: '专题活动',
					tipName: '专题活动',
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: true,
						list: [],
						mainUrl: '', ///ShopDecorate/5021f779-b3bd-47e2-a0be-17ba565cc991.jpg
						hideSpace: false
					}
				})
			},

			moveUp(index) {

				if (index <= 1) return;

				this.list.splice(index - 1, 1, ...this.list.splice(index, 1, this.list[index - 1]))

			},
			moveDown(index) {

				if (index === this.list.length - 1) return;

				this.list.splice(index + 1, 1, ...this.list.splice(index, 1, this.list[index + 1]))

			},
			remove(index) {
				this.list.splice(index, 1)
			},
			deleteMenu(index) {
				this.list.splice(index, 1)
			},
			scrollToElement(record) {
				let elem = document.querySelector('#' + record.uid);
				let box = document.querySelector('.scroll-box');

				if (elem) {
					box.scrollTop = elem.offsetTop - box.offsetTop
				}
			},
			async submit(type, callback) {
				this.$refs['ruleForm'].validate(async (valid) => {
					if (valid) {
						try {
							var list = JSON.parse(JSON.stringify(this.list));
							console.log("---list----", list);
							const params = {
								id: this.pageId,
								activityContext: JSON.stringify(list),
								activityName: this.ruleForm.activityName,
								endTime:this.ruleForm.applyTime[1],
								startTime:this.ruleForm.applyTime[0],
							}
							
							var result;
							if (params.id) {
								result = await subjectActivityupdateActivity(params);
							} else {
								result = await subjectActivitysaveActivity(params);
							}
							
							if (result.success) {
								if (!callback) {
									this.$message({
										showClose: true,
										message: '保存成功！',
										type: 'success'
									});
								}
								callback && callback();
								this.$router.replace({
									path: '/market/themeActivity/index'
								})
							
							} else {
								this.$message({
									showClose: true,
									message: result.alertMsg,
									type: 'error'
								});
							}
						} catch (e) {
							//TODO handle the exception
						}
					}
				});
				
			},
			cancel() {
				// this.$confirm('若您未保存编辑的数据，离开页面后，系统将不会为您保存数据，是否确认继续离开？')
				// 	.then(_ => {
				// 		this.$router.replace({
				// 			path: '/mallPage/mallPagemange'
				// 		})
				// 	})
				// 	.catch(_ => {});
				this.dialogVisible = true;

			},
			handleCanMove(record, index, uid) {
				this.$refs['browseBox'].hotMove(record, index, uid)
				console.log(record, index, uid)
			},
			sureCancel() {
				this.dialogVisible = false;
				this.$router.replace({
					path: '/market/themeActivity/index'
				})
			},
			preview() {

				this.submit(false, async () => {
					this.weiCodeShow = true;
					try {
						this.codeding = true
						let data = {
							Page: `pages/cusPage/cusPage`,
							Scene: `id=${this.pageId}&view=1`,
							IsLimited: false,
							ProgramType: 0,
						}
						let result = await apiList.mallGetQrCode(data)
						this.codePicture = JSON.parse(result.Result)[0]
					} catch (e) {
						console.log('e', e)
					} finally {
						this.codeding = false
					}
				})


			},
			weiCodeCancel() {
				this.weiCodeShow = false;
			},
			guid() {
				var a = function() {
					return (65536 * (1 + Math.random()) | 0).toString(16).substring(1)
				};
				return 'a' + a() + a() + "-" + a() + "-" + a() + "-" + a() + "-" + a() + a() + a()
			}
		},
		mounted() {
			this.initData();
		},
		watch: {}
	};
</script>

<style lang="less" scoped>
	.page-manage {
		display: flex;
		justify-content: space-between;
		background: #e5e5e5;
		overflow: auto;
		position: absolute;
		left: 0px;
		top: 0;
		width: 100%;
		height: 100%;

		.edit-box {
			display: flex;
			width: 480px;
			background: #fff;
			margin-left: 50px;
		}

		.item-box {
			display: flex;
			height: 100%;
		}

		.fixed-menu {
			position: fixed;
			right: 20px;
			top: 125px;
			width: 30px;
			height: 30px;
			line-height: 37px;
			background: #fff;
			text-align: center;
			z-index: 1000;
			box-shadow: 0 0 9px 1px rgba(0, 0, 0, .1);
			cursor: pointer;
		}

		.dragMenu {
			width: 240px;
			margin-left: 584px;
			position: fixed;
			top: 160px;
			right: 60px;
			bottom: 60px;
			z-index: 1000;
			box-shadow: 0 0 9px 1px rgba(0, 0, 0, .1);

			.box {
				display: flex;
				flex-direction: column;
				height: 100%;
				background: #fff;
				padding: 15px 20px;

				.title {
					font-size: 16px;
					margin-bottom: 15px;
					flex: 0 0 auto;
				}

				.item {
					position: relative;
					height: 36px;
					line-height: 36px;
					padding-left: 20px;
					font-size: 14px;
					color: #666;
					background: #f0f0f0;
					margin-bottom: 5px;
					cursor: pointer;

					.menu-delete {
						position: absolute;
						right: 10px;
						top: 2px;
						cursor: pointer;
					}
				}

				.list {
					flex: 1 1 auto;
					overflow: auto;
					padding-right: 10px;
				}
			}
		}

		.btn-box {
			position: absolute;
			right: 10px;
			bottom: 15px;
		}
	}

	* {
		user-select: none;
	}

	// ::-webkit-scrollbar {
	// width: 5px;
	// }

	// ::-webkit-scrollbar-track {
	// background-color: none;
	// }

	// ::-webkit-scrollbar-thumb {
	// background-color: none;
	// }

	// ::-webkit-scrollbar-thumb:hover {
	// background-color: none;
	// }

	// ::-webkit-scrollbar-thumb:active {
	// background-color: none;
	// }
</style>
